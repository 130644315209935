import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Modal, Radio } from 'antd';
import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies, findFiatCurrency } from '@oneecosystem/dealshaker-core';
import {
  popup,
  popupContent,
  columnContainer,
  columnTitle,
  columnListOptionsContainer,
  VerticalSeparator,
  languageOption,
} from './styles';

const LocalizationPopup = ({ open, closePopup }) => {
  const { selectedCurrency, fiatCurrencies } = useCurrencies(currenciesState =>
    pick(currenciesState, ['selectedCurrency', 'fiatCurrencies']),
  );
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();
  const theme = useTheme();

  const switchLanguage = newLanguage => {
    changeLanguage(newLanguage);
    closePopup();
  };

  const switchCurrency = value => {
    changeUserCurrency(findFiatCurrency(value));
    closePopup();
  };

  return (
    <Modal
      open={open}
      zIndex={1200}
      onCancel={closePopup}
      bodyStyle={popupContent}
      wrapClassName={popup(theme)}
      footer={null}
    >
      <section className={columnContainer}>
        <h4 className={columnTitle}>{getText('language')}</h4>
        <Radio.Group
          defaultValue={language?.code}
          buttonStyle="solid"
          onChange={e => switchLanguage(e.target.value)}
          className={columnListOptionsContainer}
        >
          {allEnvLanguages?.map(language => (
            <Radio.Button key={language.code} value={language.code} className={languageOption}>
              {language.nativeName ?? ''}
            </Radio.Button>
          ))}
        </Radio.Group>
      </section>
      <VerticalSeparator theme={theme} />

      <section className={columnContainer}>
        <h4 className={columnTitle}>{getText('currency')}</h4>
        <Radio.Group defaultValue={selectedCurrency?.code} buttonStyle="solid" className={columnListOptionsContainer}>
          {fiatCurrencies?.map(currencyItem => (
            <Radio.Button
              key={currencyItem?.code}
              onChange={e => switchCurrency(e.target.value)}
              checked={selectedCurrency?.code === currencyItem.code}
              value={currencyItem?.code}
            >
              {`${currencyItem?.symbol ?? ''} ${currencyItem?.code ?? ''}`}
            </Radio.Button>
          ))}
        </Radio.Group>
      </section>
    </Modal>
  );
};

LocalizationPopup.propTypes = {
  open: PropTypes.bool,
  closePopup: PropTypes.func,
};

export default LocalizationPopup;
