import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Rate, Popover, Modal } from 'antd';
import { useTheme } from 'emotion-theming';
import { useTranslations } from '@veraio/strank';
import FeatherIcon from 'feather-icons-react';
import { Button } from 'components/UIExternal';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';
import { ImageGalleryPanel } from 'screens/Public/DealDetailsPage/components';
import { messageWrapper, commentMessage } from 'screens/Private/MerchantOffice/components/dealsStyles';
import { galleryModal } from 'screens/Public/DealDetailsPage/styles';
import { formatDefaultDate, formatTime, formatDescriptiveDateTime } from 'utils/valueFormatter';
import useError from 'services/errorHandling/useError';
import { deleteReview, addReviewMerchantReply } from 'services';
import DefaultAvatar from 'assets/images/footer_user_default.svg';
import tickIcon from 'assets/images/icn-tick-filled.svg';
import ReplyModal from './components/ReplyModal';
import {
  mainContainer,
  avatar,
  starRatings,
  ratingsCountLabel,
  responsiveImg,
  authorNameTitle,
  avatarContainer,
  mobileAvatarContainer,
  desktopReview,
  mobileReview,
  popoverWrapper,
  disputeLabel,
  labelButtonWrapper,
  replyDetailsWrapper,
} from './styles';

const CommentReview = ({ reviews }) => {
  const theme = useTheme();
  const { setError } = useError();
  const { getText, getDynamicTranslation } = useTranslations();

  const {
    id,
    authorName,
    date,
    title,
    description,
    stars,
    authorPhoto,
    media,
    isDealOwner,
    isReviewOwner,
    inDispute,
    disputeMessageDate,
    disputeManagerUsername,
    disputeMessage,
    businessName,
    reply,
    replyDate,
  } = reviews;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isReplyModalVisible, setIsReplyModalVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleOk = async () => {
    setIsModalVisible(false);
    const [, err] = await deleteReview(id);
    err && setError(err);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReplyOk = async response => {
    setIsReplyModalVisible(false);
    const [, err] = await addReviewMerchantReply({ reviewId: id, description: response });
    err && setError(err);
  };

  const handleReplyCancel = () => {
    setIsReplyModalVisible(false);
  };

  return (
    <>
      <div className={`${mainContainer} flex`}>
        <div className={avatarContainer}>
          <img src={authorPhoto.url ? authorPhoto.url : DefaultAvatar} className={avatar(theme)} alt="Avatar" />
          <Rate className={starRatings(theme)} value={stars} disabled />
          <label className={ratingsCountLabel(theme)}>{`${stars} of 5`}</label>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className="flex">
            {/* Mobile only */}
            <div className={mobileAvatarContainer(theme)}>
              <img src={authorPhoto.url ? authorPhoto.url : DefaultAvatar} alt="Avatar" />
            </div>
            <div>
              <p className={authorNameTitle(theme)}>{authorName}</p>
              {/* Desktop */}
              <p className={desktopReview}>
                {getText('reviewedOnDateAtTime', { date: formatDefaultDate(date), time: formatTime(date) })}
              </p>
              {/* Mobile */}
              <div className={mobileReview}>
                <p>
                  {formatDefaultDate(date)}
                  <span className="bold-text"> {formatTime(date)}</span>
                </p>
                <Rate className={starRatings(theme)} value={stars} disabled />
              </div>
            </div>
            <div className={labelButtonWrapper}>
              {inDispute && <label className={disputeLabel(theme)}>{getText('inDispute')}</label>}
              {(isDealOwner || isReviewOwner) && (
                <Popover
                  content={
                    <>
                      {isDealOwner && (
                        <>
                          {!inDispute && (
                            <Button type="link" small linkTo={`/dispute-review/${id}`}>
                              {getText('dispute')}
                            </Button>
                          )}
                          {!reply && (
                            <Button type="link" small onClick={() => setIsReplyModalVisible(true)}>
                              {getText('reply')}
                            </Button>
                          )}
                        </>
                      )}
                      {isReviewOwner && (
                        <Button type="link" small linkTo={`/edit-review/${id}`}>
                          {getText('edit')}
                        </Button>
                      )}
                    </>
                  }
                  overlayClassName={popoverWrapper(theme)}
                  zIndex={900}
                  placement="bottomRight"
                  trigger="click"
                >
                  <FeatherIcon icon="more-vertical" size={20} />
                </Popover>
              )}
            </div>
          </div>
          <p className="bold-text">{title && title}</p>
          <p>{description}</p>
          <div className="flex">
            {!!media.length &&
              media.map(
                (item, idx) =>
                  item.url && (
                    <Button
                      key={idx}
                      type="link"
                      small
                      className={responsiveImg}
                      onClick={() => setIsImageModalVisible(true)}
                    >
                      <img src={item.url} alt="review comment" />
                    </Button>
                  ),
              )}
          </div>
          {reply && (
            <div>
              <div className={replyDetailsWrapper(theme)}>
                <img src={tickIcon} alt="Green tick" />
                <p>{getDynamicTranslation(businessName)?.name}</p>
                <label>
                  {getText('repliedOnDateAtTime', { date: formatDefaultDate(replyDate), time: formatTime(replyDate) })}
                </label>
              </div>
              <p>{reply}</p>
            </div>
          )}
          {disputeMessage && (
            <div className={messageWrapper(theme)} style={{ marginTop: 15 }}>
              <p>
                <span className="icon-Info-2" />
                {getText('updateOnDateByUser', {
                  date: formatDescriptiveDateTime(disputeMessageDate),
                  user: disputeManagerUsername,
                })}
              </p>
              <Button type="link" small onClick={() => setShowMessage(!showMessage)}>
                {showMessage ? getText('hideMessage') : getText('viewMessage')}
              </Button>
              {showMessage && <p className={commentMessage}>{disputeMessage}</p>}
            </div>
          )}
        </div>
      </div>
      <ConfirmationPopup
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        text={getText('sureWantRemoveThisReview')}
      />
      <Modal
        wrapClassName={galleryModal(theme)}
        open={isImageModalVisible}
        footer={null}
        destroyOnClose
        width={1300}
        onCancel={() => setIsImageModalVisible(false)}
      >
        <ImageGalleryPanel
          showNav
          items={media?.map(item => item.url && { thumbnail: item.url, original: item.url })}
        />
      </Modal>
      <ReplyModal
        isModalVisible={isReplyModalVisible}
        handleOk={handleReplyOk}
        handleCancel={handleReplyCancel}
        reviewId={id}
      />
    </>
  );
};

CommentReview.propTypes = {
  reviews: PropTypes.object,
  reloadAllLoadedPages: PropTypes.func,
};

export default CommentReview;
