import { css } from 'emotion';
import styled from '@emotion/styled';
import { Desktop, Mobile } from 'assets/css/breakpoints';

export const lightBlueHeaderContainer = theme =>
  css({
    '& > div': {
      paddingTop: 0,
      '& > div': {
        display: 'flex',
      },
    },
    backgroundColor: theme.background200,
    color: theme.gray550,
  });

export const blueHeaderContainer = theme =>
  css({
    backgroundColor: theme.background100,
  });

export const whiteHeaderContainer = theme =>
  css({
    backgroundColor: theme.white,
    boxShadow: '0px 2px 16px rgba(34, 34, 34, 0.12)',
    position: 'relative',
    zIndex: 200,
  });

export const topHeaderStyles = {
  paddingTop: '0.3125em',
  paddingBottom: '0.3125em',
};

export const middleHeader = theme =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '0.3125em',
    paddingTop: '0.3125em',
    color: theme.white,
    '& .logo-and-search': {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    '& > div.flex-center': {
      '& > a': {
        paddingLeft: '0',
      },
    },

    '& .ant-select-auto-complete': {
      width: '45.3125rem',
      marginLeft: '1.75em',
      marginTop: '0.25em',
      borderRadius: 4,
      '& .ant-select-selection-search-input': {
        borderRadius: '15px',
      },
    },
    [Mobile]: {
      display: 'flex',
      position: 'relative',
      '& > .flex.flex-center:first-child': {
        '& > div:first-child': {
          paddingLeft: '0',
        },
      },
      '& .ant-select-auto-complete': {
        width: '15.625rem',
        marginLeft: '0',
      },
    },
  });

export const logoAndSearch = css({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

export const bottomHeader = css({
  paddingTop: '0.625em',
  paddingBottom: '0.625em',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  '& .ant-select-auto-complete': {
    width: '100%',
    '& .ant-select-selection-search-input': {
      borderRadius: '15px',
    },
  },
});

export const StyledUserActionPanel = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const searchResultWrapper = css({
  borderRadius: '10px !important',
  position: 'fixed !important',
  '& .rc-virtual-list-holder': {
    maxHeight: '350px !important',
  },
  [Desktop]: { top: '5.1em !important' },
  [Mobile]: { top: '5.6em !important' },
});

export const searchIconWrapper = css({
  display: 'flex',
});

export const LogoIcon = css({
  height: '2.625rem',
  [Mobile]: {
    height: '2rem',
  },
});

export const fixedPosition = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
});

export const verticalSeparator = css({
  width: '0.0625rem',
  backgroundColor: '#888F97',
  borderRadius: '2px',
  margin: '0 0.625em',
  height: '1.25rem',
});

export const searchSuffixButtons = css({
  display: 'flex',
  alignItems: 'center',
  '& button': {
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
  },
});
