import { invert } from 'lodash-es';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { TAG_TYPE } from 'components/UIExternal/Tag';
import { EXTERNAL_PAYMENT_METHODS } from './PaymentMethod';

export const deliveryStatus = {
  packed: 1,
  ongoing: 2,
  delivered: 3,
};

export const deliveryStatusInverse = invert(deliveryStatus);

export const deliveryStatusColor = {
  [deliveryStatus.packed]: TAG_TYPE.RED,
  [deliveryStatus.ongoing]: TAG_TYPE.PURPLE,
  [deliveryStatus.delivered]: TAG_TYPE.GREEN,
};

export const deliveryStatusSelect = Object.entries(deliveryStatus).map(([label, value]) => ({ label, value }));

export const paymentStatus = {
  pending: 1,
  completed: 2,
  declined: 3,
  partiallyPaid: 4,
};

export const paymentStatusInverse = invert(paymentStatus);

export const paymentStatusColor = {
  [paymentStatus.pending]: TAG_TYPE.WARNING,
  [paymentStatus.completed]: TAG_TYPE.GREEN,
  [paymentStatus.declined]: TAG_TYPE.RED,
  [paymentStatus.partiallyPaid]: TAG_TYPE.YELLOW,
};

export const paymentStatusSelect = Object.entries(paymentStatus).map(([label, value]) => ({ label, value }));

export const orderStatus = {
  new: 1,
  completed: 4,
  declined: 6,
  canceled: 5,
  paymentFailed: 7,
  partiallyPaid: 8,
};

export const orderStatusInverse = invert(orderStatus);

export const orderStatusColor = {
  [orderStatus.new]: TAG_TYPE.BLUE,
  [orderStatus.completed]: TAG_TYPE.GREEN,
  [orderStatus.declined]: TAG_TYPE.RED,
  [orderStatus.canceled]: TAG_TYPE.RED_LIGHT,
  [orderStatus.paymentFailed]: TAG_TYPE.RED,
  [orderStatus.partiallyPaid]: TAG_TYPE.YELLOW,
};

export const orderStatusSelect = getText =>
  Object.entries(orderStatus).map(([label, value]) => ({ label: getText(label), value }));

export const orderItemStatus = {
  new: 1,
  paymentInProgress: 2,
  paymentSuccessful: 3,
  completed: 4,
  cancelled: 5,
  paymentNotConfirmed: 6,
};

export const orderItemStatusInverse = invert(orderItemStatus);

export const orderItemStatusSelect = Object.entries(orderItemStatus).map(([label, value]) => ({ label, value }));

export const paymentMethods = {
  accountBallance: 1,
  cashOnDelivery: 2,
  crypto: 3,
  bankTransfer: 4,
  cashWallet: 5,
  oneWallet: 6,
  oespWallet: 7,
  usdt: 8,
};

export const paymentMethodsInverse = invert(paymentMethods);

export const paymentMethodsSelect = Object.entries(paymentMethods).map(([label, value]) => ({ label, value }));

export const PAYMENT_METHODS_CURRENCIES = {
  [paymentMethods.usdt]: EXTERNAL_PAYMENT_METHODS.USDT.code,
  [paymentMethods.oneWallet]: CRYPTO_CURRENCIES.ONE.code,
  [paymentMethods.oespWallet]: CRYPTO_CURRENCIES.OESP.code,
};

export const EXTERNAL_PAYMENT_PROVIDER = {
  COINPAYMENTS_CURRENCY: 'COINPAYMENTS_CREATE_PAYMENT_REQUEST_CURRENCY',
};
