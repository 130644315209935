import React, { useState } from 'react';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'antd';
import { Button, Pagination, Skeleton } from 'components/UIExternal';
import { getAllBusinesses } from 'services/api/businessesService';
import useError from 'services/errorHandling/useError';
import BusinessAccountCard from './BusinessAccountCard';
import { merchantOfficeTab, titleWrapper } from './styles';
import { accountsList } from './accountsStyles';

const MerchantAccountsTab = () => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [accounts, setAccounts] = useState(null);

  const fetchAccounts = async options => {
    const [res, err] = await getAllBusinesses({ ...options });
    err ? setError(err) : setAccounts(res);
    return res;
  };

  return (
    <div className={merchantOfficeTab}>
      <div className={`${titleWrapper} ${isMobile ? 'flex-column' : 'flex'}`}>
        <Pagination onChange={fetchAccounts} pageSize={3} />
        <Button type="info" linkTo="/add-merchant-account" small>
          {getText('addNew')}
        </Button>
      </div>
      {isNil(accounts) ? (
        <Row className={accountsList} gutter={[24, 24]} justify="space-around">
          <Skeleton count={3} height={400} width="32%" />
        </Row>
      ) : (
        <Row className={accountsList} gutter={[24, 24]}>
          {accounts?.items?.map(account => (
            <Col className="gutter-row" lg={8} span={24} key={account.id}>
              <BusinessAccountCard account={account} reloadAllLoadedPages={fetchAccounts} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default MerchantAccountsTab;
