import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import ReactPlayer from 'react-player';
import { useTranslations } from '@veraio/strank';
import { getToken } from '@oneecosystem/authenticate';
import { Row, Upload, Tabs, Input, Button, Spin } from 'antd';
import { toast } from 'react-hot-toast';
import { CloudUploadOutlined, CloseOutlined } from '@ant-design/icons';
import { NestedCol } from 'components/ui';
import apiRoutes from 'config/apiRoutes';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import useError from 'services/errorHandling/useError';
import { removeMedia, setDealYoutubeVideo } from 'services/api/mediaService';
import { delay } from 'utils/queryUtils';
import { updateDealStepData } from 'services/api/dealsService';
import FormActions from '../FormActions/FormActions';
import DragSortingUpload from './components';
import { form, tabs, video, mt, imageUploadDragDrop, uploadedVideoWrapper, videoDragger } from './styles';

const StepThree = ({ stepThreeData, currentStep, setManualStep, setStepThreeData, dealId }) => {
  const [activeKey, setActiveKey] = useState('1');
  const [currentStepData, setCurrentStepData] = useState({
    youtubeLinkId: '',
    videoUploadInProgress: false,
    images: stepThreeData?.images?.map(({ id, url, thumbnailUrl }) => ({
      id,
      url,
      thumbnailUrl,
      name: id,
      status: 'done',
    })),
  });

  const theme = useTheme();
  const { setError } = useError();
  const { getText } = useTranslations();

  const { youtubeLinkId, youtubeLink, videoUploadInProgress, deleteInProgress, images } = currentStepData;

  const setCurrentState = (prop, value) => setCurrentStepData(prevStateData => ({ ...prevStateData, [prop]: value }));

  useEffect(() => {
    if (stepThreeData?.link?.url?.length) setCurrentState('youtubeLinkId', stepThreeData?.link?.url);
    if (stepThreeData?.link?.url?.length)
      setCurrentState('youtubeLink', `www.youtube.com/watch?v=${stepThreeData.link.url}`);
  }, [stepThreeData]);

  const imgUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    accept: MediaTypesEnum.Image.allowedTypes.join(', '),
    action: apiRoutes.MEDIA_ADD_DEAL_IMAGE(dealId),
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: file => {
      if (file.size > MediaTypesEnum.Image.allowedSize) {
        toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Image.allowedTypes.includes(file.type)) {
        toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        setCurrentState('images', images.concat(info.file.response));
        delay(() => toast.success(getText('uploadSuccessful'), { duration: 1500 }));
      }
      if (status === 'error') toast.error(getText('fileUploadFailed'));
    },
  };

  const videoUploadProps = {
    name: 'file',
    disabled: !!stepThreeData?.video || videoUploadInProgress,
    accept: MediaTypesEnum.Video.allowedTypes.join(', '),
    showUploadList: false,
    action: apiRoutes.MEDIA_ADD_VIDEO(dealId),
    headers: { Authorization: `Bearer ${getToken().access_token}` },
    beforeUpload: file => {
      if (file.size > MediaTypesEnum.Video.allowedSize) {
        toast.error(`${file.name} ${getText('uploadFailedFileSizeBigger')}`);
        return Upload.LIST_IGNORE;
      }
      if (!MediaTypesEnum.Video.allowedTypes.includes(file.type)) {
        toast.error(`${file.name} ${getText('uploadFailedWrongFormat')}`);
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      setCurrentState('videoUploadInProgress', true);
      const { status } = info.file;
      if (status !== 'uploading') setStepThreeData({ ...stepThreeData, video: info.file.response });
      if (status === 'done') {
        setCurrentState('videoUploadInProgress', false);
        toast.success(`${info.file.name} ${getText('uploadSuccessful')}`);
      } else if (status === 'error') {
        setCurrentState('videoUploadInProgress', false);
        toast.error(`${info.file.name} ${getText('uploadFailed')}`);
      }
    },
  };

  const getEmbeddedUrl = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2] ? match[2] : null;
  };

  const onLinkChangeHandler = e => {
    const { value } = e.target;
    setCurrentState('youtubeLink', value);
    if (value.length > 5) {
      const videoId = getEmbeddedUrl(value);
      videoId && setCurrentState('youtubeLinkId', videoId);
    } else setCurrentState('youtubeLinkId', '');
  };

  const onRemoveVideo = async id => {
    const [, err] = await removeMedia(id);
    err ? setError(err) : setStepThreeData({ ...stepThreeData, video: null });
  };

  const onRemoveImage = async id => {
    setCurrentState('deleteInProgress', true);
    const [, err] = await removeMedia(id, setError);
    err && setError(err);
    setCurrentState(
      'images',
      images.filter(img => img.id !== id),
    );
    toast.success(getText('mediaRemovedSuccessfully'), { duration: 1500 });
    setCurrentState('deleteInProgress', false);
  };

  const onNextStepHandler = () => {
    if (dealId) {
      setDealYoutubeVideo(dealId, youtubeLinkId).then(r => {
        if (r[2] === 200) {
          updateDealStepData({ images: images.map(({ id }, i) => ({ id, order: i })) }, 3, dealId, setError).then(() =>
            setManualStep(4),
          );
        }
      });
    }
  };
  const onSkipStepHandler = () => {
    if (dealId) updateDealStepData({ images: [] }, 3, dealId, setError).then(() => setManualStep(4));
  };

  return (
    <Row className={form} gutter={[24, 0]}>
      <NestedCol lg={13} span={24} totalWidth={14}>
        <h6>{getText('uploadImages')}</h6>
        <p>{getText('optimalResultsForUploadDeal')}</p>
      </NestedCol>
      <NestedCol lg={7} span={24} totalWidth={14}>
        <Upload.Dragger className={imageUploadDragDrop} {...imgUploadProps}>
          <p className="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p className="ant-upload-text">{getText('dragAndDropBrowseFiles')}</p>
        </Upload.Dragger>
      </NestedCol>
      <NestedCol lg={7} span={24} totalWidth={14}>
        {images?.length ? (
          !deleteInProgress ? (
            <DragSortingUpload
              items={images}
              changeImagesOrder={setCurrentState}
              onRemove={({ id }) => onRemoveImage(id)}
            />
          ) : (
            <div className="content-container">
              <Spin size="large" />
            </div>
          )
        ) : (
          <></>
        )}
      </NestedCol>
      <NestedCol className={mt(83)} lg={10} span={24} totalWidth={14}>
        <Tabs
          hideAdd
          destroyInactiveTabPane
          onChange={setActiveKey}
          activeKey={activeKey}
          className={tabs(theme)}
          type="editable-card"
        >
          <Tabs.TabPane className={mt(45)} key={1} closable={false} tab={getText('embedVideo')}>
            <label htmlFor="link">{getText('youtubeLink')}</label>
            <Input id="link" value={youtubeLink} onChange={onLinkChangeHandler} />
          </Tabs.TabPane>
          <Tabs.TabPane key={2} closable={false} tab={getText('uploadVideo')}>
            <Upload.Dragger className={videoDragger(!!stepThreeData?.video)} {...videoUploadProps}>
              <p className="ant-upload-drag-icon">
                {videoUploadInProgress ? <Spin size="large" /> : <CloudUploadOutlined />}
              </p>
              <p className="ant-upload-text">
                {stepThreeData?.video ? getText('videoUploadedAlready') : getText('dragAndDropBrowseFiles')}
              </p>
            </Upload.Dragger>
          </Tabs.TabPane>
        </Tabs>
      </NestedCol>
      <NestedCol className={mt(120)} lg={4} span={24} totalWidth={14}>
        {youtubeLinkId && youtubeLinkId.length && youtubeLink.length && activeKey === '1' ? (
          <ReactPlayer className={video(theme, true)} controls url={`www.youtube.com/embed/${youtubeLinkId}`} />
        ) : (
          <></>
        )}
        {stepThreeData?.video && activeKey === '2' && (
          <div className={uploadedVideoWrapper(theme)}>
            <ReactPlayer className={`${video(theme)}`} controls url={stepThreeData?.video.url} />
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => onRemoveVideo(stepThreeData?.video.id)}
              size="small"
            />
          </div>
        )}
      </NestedCol>
      <NestedCol lg={14} span={24} totalWidth={14}>
        <FormActions
          currentStep={currentStep}
          onSkipStepHandler={onSkipStepHandler}
          setManualStep={setManualStep}
          onNextStepHandler={onNextStepHandler}
        />
      </NestedCol>
    </Row>
  );
};

StepThree.propTypes = {
  stepThreeData: PropTypes.object,
  setStepThreeData: PropTypes.func,
  setManualStep: PropTypes.func,
  currentStep: PropTypes.number,
  dealId: PropTypes.number,
};
export default StepThree;
