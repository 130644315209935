import React from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Icon } from 'components/ui';
import { ReactComponent as EmptyIcon } from '../../../assets/images/empty-icon.svg';
import { noResultsContainer, noResultsTitle, noResultsIcon, noResultsSubtitle } from './styles';

export const NoResults = ({ emptyMessage }) => (
  <section className={noResultsContainer}>
    <EmptyIcon className={noResultsIcon} />
    <p>{emptyMessage ?? getText('thereAreNoRecords')}</p>
  </section>
);

NoResults.propTypes = {
  emptyMessage: PropTypes.string,
};

export const Error = () => (
  <section className={noResultsContainer}>
    <div className={noResultsIcon}>
      <Icon material iconName="report" size={48} color="white" />
    </div>
    <h2 className={noResultsTitle}>Request failure.</h2>
    <p className={noResultsSubtitle}>
      There was problem getting the results.
      <br />
      Please try again later.
    </p>
  </section>
);
