import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import { get, isFunction, isPlainObject, isString, startCase } from 'lodash-es';
import { validDate } from 'utils';
import { CheckButton } from '../Button';
import { tableRow, tableCell, tableCellEmpty, tableCellCheckButton } from './styles';

const NullItem = () => <div className={tableCellEmpty} />;

export const displayValue = (column, row) => {
  const value = get(row, column.value.split('.'));

  switch (true) {
    case isPlainObject(column.mapValue):
      return column.mapValue[value.toLowerCase()];
    case isString(column.dateTimeFormat):
      return validDate(value) ? moment(value).format(column.dateTimeFormat) : <NullItem />;
    case column.startCase:
      return startCase(value);
    default:
      return value ?? <NullItem />;
  }
};

const TableBody = props => {
  const { data, columns, selectRow, hasSelection, renderRow, onRowClick, rowLink } = props;
  const theme = useTheme();

  const RowComp = isFunction(rowLink) ? Link : 'div';

  return data.map((row, i) =>
    isFunction(row.render) || isFunction(renderRow) ? (
      (row.render ?? renderRow)({
        row,
        NullItem,
        columns,
        rowClassName: tableRow(theme),
        columnClassName: tableCell,
        displayValue,
      })
    ) : (
      <RowComp
        key={`table-row-${i}`}
        className={tableRow(isFunction(rowLink) || isFunction(onRowClick), row?._table?.isSelected, theme)}
        {...(isFunction(rowLink) && rowLink(row))}
        {...(isFunction(onRowClick) && { onClick: () => onRowClick(row) })}
      >
        {hasSelection && (
          <div
            role="presentation"
            className={tableCell({ flex: '0 0 40px', render: true })}
            onClick={e => e.stopPropagation()}
          >
            <CheckButton
              onChange={value => selectRow(value, row)}
              checked={row?._table?.isSelected}
              className={tableCellCheckButton}
            />
          </div>
        )}
        {columns.map((column, j) => (
          <div key={`table-column${i}-${j}`} className={tableCell(column)}>
            {isFunction(column.render) ? column?.render(row, NullItem) : displayValue(column, row)}
          </div>
        ))}
      </RowComp>
    ),
  );
};

TableBody.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  selectRow: PropTypes.func,
  hasSelection: PropTypes.bool,
  renderRow: PropTypes.func,
};

export default TableBody;
