import apiRoutes from 'config/apiRoutes';
import { getReq, postReq, putReq, deleteReq } from 'services/axios/makeRequest';
import envConfig from 'config/envConfig';

const baseUrl = `${envConfig.apiAddress}/api/Businesses`;

export const getAllBusinesses = params => getReq(`${baseUrl}/List`, params);

export const getAllBusinessesInfo = params => getReq(`${baseUrl}/ListInfo`, params);

export const getSingleBusiness = businessId => getReq(`${baseUrl}/Get/${businessId}`);

export const getBusinessOverview = businessId => getReq(apiRoutes.BUSINESSES_GET_OVERVIEW, { businessId });

export const getBusinessProfile = businessId => getReq(`${baseUrl}/Profile/${businessId}`);

export const addNewBusiness = payload => postReq(`${baseUrl}/Create`, payload);

export const deleteBusiness = businessId => deleteReq(`${baseUrl}/Delete/${businessId}`);

export const updateBusiness = payload => putReq(`${baseUrl}/Update`, payload);

export const resetBusinessBin = id => putReq(`${baseUrl}/ResetBin/${id}`);

export const getAllNearByBusinesses = params => getReq(`${baseUrl}/Nearest`, params);

export const getBusinessLocationByBusinessName = params => getReq(`${baseUrl}/LocationSearch`, params);
