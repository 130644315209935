import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { isNil, isNumber } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import {
  useLocations,
  printPrice,
  CRYPTO_CURRENCIES,
  useCurrencies,
  DEFAULT_CRYPTO_CURRENCY,
} from '@oneecosystem/dealshaker-core';
import { Button, CheckButton } from 'components/UIExternal';
import { PaymentMethodWallet } from 'components/screens';
import { useUser } from 'stores';
import { paymentMethods, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { getPrecisionByExternalPaymentMethod } from 'utils';
import { actionButtons } from '../styles';
import { paymentMethodsContainer, paymentMethodTitle, paymentMethodContent, paymentMethodCheckbox } from './styles';

const basicCheckboxConfig = {
  size: '0.8rem',
  colorChecked: 'primary_blue',
  colorUnChecked: 'skyblue',
};

const CheckoutPaymentMethods = props => {
  const { shoppingCart, onBack, onSubmit, goNext } = props;
  const { getText } = useTranslations();
  const { currentAccount } = useUser();
  const selectedCurrency = useCurrencies(currenciesStore => currenciesStore?.selectedCurrency);
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);
  const theme = useTheme();
  const isRestricted = geoLocation?.isCryptoRestricted;
  const { cashWalletBalance, coinWalletBalance, oespWalletBalance } = currentAccount;
  const hasEuro = isNumber(shoppingCart?.totalBasePriceFiat);
  const totalBasePriceFiat = shoppingCart?.discountedTotalBasePriceFiat ?? shoppingCart?.totalBasePriceFiat;
  const totalLocalPriceFiat = shoppingCart?.discountedTotalLocalPriceFiat ?? shoppingCart?.totalLocalPriceFiat;
  const totalPriceCrypto = shoppingCart?.discountedTotalPriceCrypto ?? shoppingCart?.totalPriceCrypto;
  const insufficientEuro = totalBasePriceFiat > cashWalletBalance;
  const insufficientOne = totalPriceCrypto > coinWalletBalance;
  const insufficientOesp = totalPriceCrypto > oespWalletBalance;

  useEffect(() => {
    isNil(shoppingCart?.cashPaymentMethodId) && isNil(shoppingCart?.cryptoPaymentMethodId) && selectDefaultPayments();
  }, []);

  const cashPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cashPaymentMethodId === type,
    disabled: shoppingCart?.cashPaymentMethodId === type,
    onChange: () => onSubmit({ cashPaymentMethod: type }),
    className: paymentMethodCheckbox(type === paymentMethods.cashWallet),
  });

  const cryptoPaymentsProps = type => ({
    ...basicCheckboxConfig,
    checked: shoppingCart?.cryptoPaymentMethodId === type,
    onChange: () => onSubmit({ cryptoPaymentMethod: type }),
    className: paymentMethodCheckbox(true),
  });

  const selectDefaultPayments = () => {
    const defaultPaymentMethods = {
      cashPaymentMethod: paymentMethods.cashWallet,
      cryptoPaymentMethod: paymentMethods.oneWallet,
    };

    if (insufficientEuro) defaultPaymentMethods.cashPaymentMethod = paymentMethods.cashOnDelivery;
    if (isRestricted || insufficientOne) defaultPaymentMethods.cryptoPaymentMethod = paymentMethods.oespWallet;

    onSubmit(defaultPaymentMethods);
  };

  return (
    <>
      {hasEuro && (
        <>
          <h4 className={paymentMethodTitle}>
            {getText('payWithAmount', {
              amount: printPrice({
                fiat: totalLocalPriceFiat,
                fiatOriginCurrency: selectedCurrency?.code,
                fiatPrecision: getPrecisionByExternalPaymentMethod(selectedCurrency?.code),
              }),
            })}
          </h4>
          <section className={paymentMethodsContainer(theme)} data-id="checkout-fiat-payment-methods">
            <div className={paymentMethodContent}>
              <div data-id="checkout-payment-method-cash-wallet">
                <CheckButton
                  {...cashPaymentsProps(paymentMethods.cashWallet)}
                  disabled={shoppingCart?.cashPaymentMethodId === paymentMethods.cashWallet || insufficientEuro}
                >
                  <PaymentMethodWallet
                    label={getText('cashWallet')}
                    balanceLabelPrintPriceConfig={{
                      fiat: cashWalletBalance,
                      fiatPrecision: getPrecisionByExternalPaymentMethod(selectedCurrency?.code),
                    }}
                    insufficientFunds={insufficientEuro}
                  />
                </CheckButton>
              </div>
              <hr />
              <div data-id="checkout-payment-method-cash-on-delivery">
                <CheckButton {...cashPaymentsProps(paymentMethods.cashOnDelivery)}>
                  <h5 className="title">{getText('cashAtDelivery')}</h5>
                </CheckButton>
              </div>
              <hr />
              <div data-id="checkout-payment-method-bank-transfer">
                <CheckButton {...cashPaymentsProps(paymentMethods.bankTransfer)}>
                  <h5 className="title">{getText('bankTransfer')}</h5>
                </CheckButton>
              </div>
              <hr />
              <div data-id="checkout-payment-method-usdt">
                <CheckButton {...cashPaymentsProps(paymentMethods.usdt)}>
                  <h5 className="title">{getText('usdt')}</h5>&nbsp;
                  <img src="https://www.coinpayments.net/images/pub/buynow-grey.png" alt="usdt" />
                </CheckButton>
              </div>
            </div>
          </section>
        </>
      )}

      <h4 className={paymentMethodTitle}>
        {getText('payWithAmount', {
          amount: printPrice({
            crypto: totalPriceCrypto,
            cryptoDesiredCurrency:
              PAYMENT_METHODS_CURRENCIES[shoppingCart.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY.code,
          }),
        })}
      </h4>
      <section className={paymentMethodsContainer(theme)} data-id="checkout-crypto-payment-methods">
        <div className={paymentMethodContent}>
          {!isRestricted && (
            <div data-id="checkout-payment-method-one">
              <CheckButton
                {...cryptoPaymentsProps(paymentMethods.oneWallet)}
                disabled={shoppingCart?.cryptoPaymentMethodId === paymentMethods.oneWallet || insufficientOne}
              >
                <PaymentMethodWallet
                  label={getText('oneWallet')}
                  balanceLabelPrintPriceConfig={{
                    crypto: coinWalletBalance,
                    cryptoDesiredCurrency: CRYPTO_CURRENCIES.ONE.code,
                  }}
                  insufficientFunds={insufficientOne}
                />
              </CheckButton>
              <hr />
            </div>
          )}
          <div data-id="checkout-payment-method-oesp">
            <CheckButton
              {...cryptoPaymentsProps(paymentMethods.oespWallet)}
              disabled={shoppingCart?.cryptoPaymentMethodId === paymentMethods.oespWallet || insufficientOesp}
            >
              <PaymentMethodWallet
                label={getText('oespWallet')}
                balanceLabelPrintPriceConfig={{
                  crypto: oespWalletBalance,
                  cryptoDesiredCurrency: CRYPTO_CURRENCIES.OESP.code,
                  ignoreEmpty: true,
                }}
                insufficientFunds={insufficientOesp}
              />
            </CheckButton>
          </div>
        </div>
      </section>

      <div className={actionButtons}>
        <Button type="default" small onClick={onBack}>
          {getText('back')}
        </Button>
        <Button
          small
          onClick={() => goNext()}
          disabled={!shoppingCart?.cashPaymentMethodId || !shoppingCart?.cryptoPaymentMethodId}
        >
          {getText('next')}
        </Button>
      </div>
    </>
  );
};

CheckoutPaymentMethods.propTypes = {
  onBack: PropTypes.func,
  shoppingCart: PropTypes.object,
  onSubmit: PropTypes.func,
  goNext: PropTypes.func,
};

export default CheckoutPaymentMethods;
