import React, { useState, useEffect } from 'react';
import { Form, Input, Checkbox, Row, Col, Modal } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { cryptoSign, useCurrencies } from '@oneecosystem/dealshaker-core';
import { toast } from 'react-hot-toast';
import { useTheme } from 'emotion-theming';
import { Button, Breadcrumbs, Dropdown } from 'components/UIExternal';
import { getBankDetails, createBankDetails, updateBankDetails } from 'services/api/bankDetails';
import useError from 'services/errorHandling/useError';
import { blackTextLink } from 'assets/css/globalCSS';
import { bankDetailsConfig } from './config';
import {
  breadcrumbsStyles,
  titleForm,
  formNote,
  accountFormWrapper,
  formSectionWrapper,
  formSubTitle,
  baseInput,
  agreementCheckboxWrapper,
  acceptLabel,
  rulesModal,
  acceptCheckboxWrapper,
  currencyDropdown,
  formButtons,
} from './styles';

const BankDetails = () => {
  const { getText } = useTranslations();
  const theme = useTheme();
  const fiatCurrencies = useCurrencies(currenciesState => currenciesState?.fiatCurrencies);
  const { setError } = useError();
  const { account } = useLocation();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [tandCs, setTandCs] = useState({ termsAccepted: false, policyAccepted: false });
  const [details, setDetails] = useState();
  const [infoModal, setInfoModal] = useState({
    isToCModalVisible: false,
    isPolicyModalVisible: false,
  });
  const history = useHistory();

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const fetchBankDetails = async () => {
    const [res, err] = await getBankDetails(account?.id);
    res?.id ? setEditMode(false) : setEditMode(true);
    return err ? setError(err) : (form.setFieldsValue(res), setDetails(res));
  };

  const addBankDetails = async data => {
    const [, err] = await createBankDetails(data);
    if (err) return setError(err);

    history.goBack();
    toast.success(getText('successAddBankDetails'));
  };

  const updateBank = async data => {
    const [, err] = await updateBankDetails(data);
    if (err) return setError(err);

    history.goBack();
    toast.success(getText('successEditBankDetails'));
  };

  const submitBankDetails = values => {
    const payload = {
      accountId: account?.accountId,
      bussinessId: account?.id,
      ...values,
    };

    editMode ? addBankDetails(payload) : updateBank({ ...payload, id: details.id });
  };

  const toggleInfoModal = (prop, value) => setInfoModal(prev => ({ ...prev, [prop]: value }));

  const toggleTAndC = (prop, value) => setTandCs(prev => ({ ...prev, [prop]: value }));

  return (
    <div className="content-container">
      <Breadcrumbs className={breadcrumbsStyles(theme)} items={bankDetailsConfig(getText)} />
      <h2 className={titleForm(theme)}>{getText('bankDetails')}</h2>
      <p className={formNote}>{getText('expectedReview')}</p>
      <>
        <Row>
          <Col lg={12} span={24} className={accountFormWrapper}>
            <Form form={form} onFinish={submitBankDetails} layout="vertical">
              <div className={formSectionWrapper(theme)}>
                <p className={formSubTitle(theme)}>{getText('bankDetails')}</p>
                <div className={acceptCheckboxWrapper(theme)} />
                <Form.Item
                  label={getText('merchantFullName')}
                  name="fullName"
                  className={baseInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('enterFullName')} />
                </Form.Item>
                <Form.Item
                  label={getText('bankName')}
                  name="bankName"
                  className={baseInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('enterNameOfTheBank')} />
                </Form.Item>
                <Form.Item
                  label={getText('bankAccountNumber')}
                  name="iban"
                  className={baseInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const format = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
                        if (!value || format.test(getFieldValue('iban')))
                          return Promise.reject(new Error(getText('noEmptySpaceOrSpecialSymbols')));

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    type="text"
                    placeholder={getText('enterBankAccountNumber')}
                    onChange={e => e.target.value.trim()}
                  />
                </Form.Item>
                <Form.Item
                  label={getText('bicSwiftCode')}
                  name="bic"
                  className={baseInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const format = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
                        if (!value || format.test(getFieldValue('bic')))
                          return Promise.reject(new Error(getText('noEmptySpaceOrSpecialSymbols')));

                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="text" placeholder={getText('enterBicSwiftCode')} onChange={e => e.target.value.trim()} />
                </Form.Item>
                <Form.Item
                  label={getText('address')}
                  name="address"
                  className={baseInput}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                    {
                      max: 128,
                      message: getText('addressLineShorterThanSymbolsCount', { count: 128 }),
                    },
                  ]}
                >
                  <Input type="text" placeholder={getText('enterAddress')} />
                </Form.Item>
                <Form.Item
                  label={getText('preferredCurrency')}
                  name="currency"
                  className={currencyDropdown}
                  rules={[
                    {
                      required: true,
                      message: getText('fieldIsRequired'),
                    },
                  ]}
                >
                  <Dropdown
                    noClear
                    placeholder={getText('currency')}
                    options={fiatCurrencies?.map(el => ({ ...el, label: `${el?.code} ${el?.symbol}` }))}
                    uniqueKey="code"
                    mapValue={el => el?.code}
                  />
                </Form.Item>
              </div>
            </Form>
            <div className={agreementCheckboxWrapper(theme)}>
              <Checkbox onChange={e => toggleTAndC('termsAccepted', e.target.checked)} />
              <p>{getText('iAcceptDealshaker')}</p>
              <Button
                type="link"
                small
                className={rulesModal}
                onClick={() => toggleInfoModal('isToCModalVisible', true)}
              >
                {getText('termAndConditions')}
              </Button>
              <Modal
                title={getText('termAndConditions')}
                open={infoModal.isToCModalVisible}
                onOk={() => toggleInfoModal('isToCModalVisible', false)}
                onCancel={() => toggleInfoModal('isToCModalVisible', false)}
              >
                <p>{getText('termsAndConditionsText', { isCrypto: cryptoSign() })}</p>
              </Modal>
            </div>
            <div className={agreementCheckboxWrapper(theme)}>
              <Checkbox onChange={e => toggleTAndC('policyAccepted', e.target.checked)} />
              <p>{getText('iAcceptDealshaker')}</p>
              <Button
                type="link"
                small
                className={rulesModal}
                onClick={() => toggleInfoModal('isPolicyModalVisible', true)}
              >
                {getText('privacyPolicy')}
              </Button>
              <Modal
                title={getText('privacyPolicy')}
                open={infoModal.isPolicyModalVisible}
                onOk={() => toggleInfoModal('isPolicyModalVisible', false)}
                onCancel={() => toggleInfoModal('isPolicyModalVisible', false)}
              >
                <p>{getText('privacyPolicyText')}</p>
              </Modal>
            </div>
            {(!tandCs.spolicyAccepted || !tandCs.termsAccepted) && (
              <label className={acceptLabel(theme)}>{getText('acceptTocPrivacyPolicy')}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} span={24} className={formButtons}>
            <Button type="link" small className={blackTextLink(theme)} onClick={() => history.goBack()}>
              {getText('goBack')}
            </Button>
            <Button
              type="primary"
              small
              disabled={!tandCs.termsAccepted || !tandCs.policyAccepted}
              onClick={() => form.submit()}
            >
              {getText('save')}
            </Button>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default BankDetails;
